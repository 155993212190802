"use client";

import React, { useEffect } from "react";
import { SessionProvider } from "next-auth/react";

export default function AuthProvider({
  children,
  session,
}: {
  children: React.ReactNode;
  session?: any; // fix later
}) {
  return <SessionProvider session={session}>{children}</SessionProvider>;
}
