// app/components/DatadogRumInitializer.tsx
"use client";

import { useEffect } from "react";
import { datadogRum } from "@datadog/browser-rum";

export default function DatadogRumInitializer() {
  useEffect(() => {
    if (!window.__datadogRumInitialized) {
      datadogRum.init({
        applicationId: process.env.NEXT_PUBLIC_DATADOG_RUM_APP_ID || "",
        clientToken: process.env.NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN || "",
        // `site` refers to the Datadog site parameter of your organization
        // see https://docs.datadoghq.com/getting_started/site/
        site: process.env.NEXT_PUBLIC_SITE,
        service: process.env.NEXT_PUBLIC_DATADOG_RUM_SERVICE_NAME,
        env: process.env.NEXT_PUBLIC_DATADOG_ENV,
        // Specify a version number to identify the deployed version of your application in Datadog
        version: '1.0.0',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: "mask-user-input",
      });

      datadogRum.startSessionReplayRecording(); // Optional: For session replay

      window.__datadogRumInitialized = true; // Set a flag to indicate that RUM has been initialized
    }
  }, []);

  return null;
}
