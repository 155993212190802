"use client";
import React, { useEffect } from "react";
import { usePathname } from "next/navigation";
import { signOut, useSession } from "next-auth/react";

import { isUserDeleted } from "@app/admin/_queries/userQueries";
import { findSession } from "@queries/session";

const SessionHandler = () => {
  const session = useSession();
  const pathname = usePathname();

  useEffect(() => {
    const logout = () => {
      signOut({ redirect: true, callbackUrl: "/" });
    };
    const handleSession = async () => {
      const sessionExpired = session?.data?.user?.sessionExpired;
      if (sessionExpired) {
        logout();
      }

      const isDeleted = await isUserDeleted(session?.data?.user?.id);

      if (isDeleted) logout();
    };

    const checkSessionExists = async () => {
      const sessionExists = await findSession(
        session?.data?.user?.id,
        session?.data?.user?.sessionId
      );
      if (!sessionExists?.id) logout();
    };

    if (session?.data?.user) {
      checkSessionExists();
      handleSession();
    }
  }, [session, pathname]);

  return null;
};

export default SessionHandler;
