"use client";
import AOS from "aos";
import { ReactNode, useEffect } from "react";

import "aos/dist/aos.css";

const AOSComponent = ({ children }: { children?: ReactNode }) => {
  useEffect(() => {
    AOS.init();
  }, []);

  return children;
};

export default AOSComponent;
