"use client";
import React, {
  ReactNode,
  createContext,
  useContext,
  useLayoutEffect,
  useState,
} from "react";
import { SiteSettings } from "@prisma/client";
import axios from 'axios'

interface SiteSettingsContextProps {
  siteSettings: SiteSettings | null;
}

const SiteSettingsContext = createContext<SiteSettingsContextProps | undefined>(
  undefined
);

export const SiteSettingsProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [siteSettings, setSiteSettings] = useState<SiteSettings | null>(null);
  useLayoutEffect(() => {
   ( async () => {
      const settings = await axios(
        `${process.env.NEXT_PUBLIC_API_URL}/siteSettings`,
        {
          next: {
            revalidate: 600,
          },
        } as any
      ).then((res) => res.data);
      setSiteSettings(settings?.results);
    })()
  }, []);

  return (
    <SiteSettingsContext.Provider value={{ siteSettings }}>
      {children}
    </SiteSettingsContext.Provider>
  );
};

export const useSiteSettings = () => {
  const context = useContext(SiteSettingsContext);
  if (!context) {
    throw new Error(
      "useSiteSettings must be used within a SiteSettingsProvider"
    );
  }
  return context.siteSettings;
};
